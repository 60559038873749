import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#38a49d",
    },
    secondary: {
      main: "#f34235",
    },
  },
  props: {
    MuiLink: {
        color: "primary",
        underline: "none",
        textDecoration: "none",
    },
    
    MuiButton: {
      color: "primary",
    },
    MuiSvgIcon: {
      color: "primary",
    },
    MuiTypography:{
      color: "primary"
    }
  }
})

export default theme
